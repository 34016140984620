import React from "react";

import LinkButton from "components/LinkButton";
import LinkButtonGroup from "components/LinkButtonGroup";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import TextHeading from "components/TextHeading";
import PropTypes from "prop-types";
import genericModalStyles from "helpers/genericModalStyles";

import "widgets/Donation/_style.scss";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

export default function DonationFeeConfirmation(props) {
  const {
    chuffedAmount,
    donationAmount,
    feeAmount,
    feeStructure: {
      currency,
      optionalPlatformDonations,
    },
    frequencyLong,
    frequencyShort,
    isSubmitting,
    onEdit,
    onPay,
    platformFeeAmount,
    settings,
    totalAmount,
  } = props;
  const { translate: t } = useLanguageProvider();
  const feeVerb = t(optionalPlatformDonations === true ? " it will attract" : " we will deduct");
  const getCardBrand = () => {
    switch (settings.cardBrand) {
      case "amex":
        return "American Express";
      default:
        return settings.cardBrand;
    }
  };

  return (
    <Modal
      contentLabel={t("Card fee confirmation")}
      appElement={document.querySelectorAll("[data-test=\"donation-root\"]")[0]}
      customStyle={genericModalStyles}
      isOpen={settings.isVisible}
      onRequestClose={onEdit}
      shouldCloseOnOverlayClick={false}
    >
      <ModalContent
        action={(
          <LinkButtonGroup>
            <LinkButton
              isDisabled={isSubmitting}
              look="plain"
              onClick={onEdit}
            >
              {t("Edit Card")}
            </LinkButton>
            <LinkButton
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              loadingMessage={t("Processing... ")}
              isBold
              look="primary"
              onClick={onPay}
            >
              {t("Accept and Pay")}
            </LinkButton>
          </LinkButtonGroup>
          )}
        body={(
          <div>
            {settings.type === "COUNTRY" && (
            <p>{t("This card was issued in a country (:cardCountry) outside of this campaign's region (:campaignRegion), meaning :feeVerb a higher payment processing fee.", { cardCountry: settings.cardCountry, campaignRegion: settings.domesticRegionCode, feeVerb })}</p>
            )}
            {settings.type === "CARD_BRAND" && (
            <p>
              {t("This card type (:cardBrand) is different to the one chosen, meaning :feeVerb a higher payment processing fee.", { cardBrand: getCardBrand(), feeVerb })}
            </p>
            )}
            <div className="donation-fee-confirmation">
              <TextHeading level={0} size={6}>
                {t("New payment summary")}
              </TextHeading>
              <div className="donation-fee-confirmation__line-item">
                <span className="donation-fee-confirmation__line-item-label">
                  {t("To Campaign")}
                  :
                </span>
                <span className="donation-fee-confirmation__line-item-value">
                  {donationAmount}
                </span>
              </div>
              {optionalPlatformDonations === true
                  && (
                  <div className="donation-fee-confirmation__line-item">
                    <span className="donation-fee-confirmation__line-item-label">
                      {t("Payment processing fee")}
                      :
                    </span>
                    <span className="donation-fee-confirmation__line-item-value">
                      {feeAmount}
                    </span>
                  </div>
                  )}
              {optionalPlatformDonations === true
                  && (
                  <div className="donation-fee-confirmation__line-item">
                    <span className="donation-fee-confirmation__line-item-label">
                      {t("Tip to Chuffed.org")}
                      :
                    </span>
                    <span className="donation-fee-confirmation__line-item-value">
                      {chuffedAmount}
                    </span>
                  </div>
                  )}
              {optionalPlatformDonations === false
                  && (
                  <div className="donation-fee-confirmation__line-item">
                    <span className="donation-fee-confirmation__line-item-label">
                      {t("Fee deducted")}
                      :
                    </span>
                    <span className="donation-fee-confirmation__line-item-value">
                      {platformFeeAmount}
                    </span>
                  </div>
                  )}
              <div className="donation-fee-confirmation__line-item --total">
                <span className="donation-fee-confirmation__line-item-label">
                  {t("Total amount:frequency:", { frequency: frequencyLong })}
                </span>
                <span className="donation-fee-confirmation__line-item-value">
                  <span className="donation-fee-confirmation__line-item-currency">
                    {currency.toUpperCase()}
                  </span>
                  <span className="donation-fee-confirmation__line-item-total">
                    {totalAmount}
                  </span>
                  {frequencyShort.length > 0
                      && (
                      <span className="donation-fee-confirmation__line-item-suffix">
                        {t(`${frequencyShort}`)}
                      </span>
                      )}
                </span>
              </div>
            </div>
          </div>
          )}
        heading={optionalPlatformDonations === true ? t("Card fee confirmation") : t("Fee transparency statement")}
      />
    </Modal>
  );
}

DonationFeeConfirmation.propTypes = {
  chuffedAmount: PropTypes.string.isRequired,
  donationAmount: PropTypes.string.isRequired,
  feeAmount: PropTypes.string.isRequired,
  feeStructure: PropTypes.object.isRequired,
  frequencyLong: PropTypes.string,
  frequencyShort: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onPay: PropTypes.func.isRequired,
  platformFeeAmount: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  totalAmount: PropTypes.string.isRequired,
};
