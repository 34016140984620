import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import PerkCard from "components/PerkCard";

import "./_style.scss";

import { fetchPerksCall as fetchPerksAction } from "./state";

const Perks = (props) => {
  const {
    campaign,
    perks,
    fetchPerksCall,
    fundraiserId,
    hasPerks,
  } = props;

  const scrollToPerks = window.Chuffed && window.Chuffed.campaignInit.scrollToSection === "perks";
  const perksRef = useRef();

  useEffect(() => {
    if (campaign) {
      fetchPerksCall(campaign.id);
    }
  }, [campaign, fetchPerksCall]);

  useEffect(() => {
    if (perks && perks.length > 0) {
      hasPerks(true);
    }

    if (scrollToPerks && perksRef.current) {
      setTimeout(() => {
        perksRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 1000);
    }
  }, [perks, hasPerks, scrollToPerks]);

  if (!campaign) {
    return null;
  }

  return (
    <div className="perks-container" ref={perksRef}>
      <h2 className="perks-container__header --large">Perks</h2>
      <ul data-test="perks-items">
        {
          perks.length > 0
          && perks.map((perk) => !perk.is_hidden && (
            <li key={`perk ${perk.title}`} data-test="campaign-perk-card">
              <PerkCard
                isInfinity={campaign.duration.isInfinityMode}
                campaignStatus={campaign.status}
                currencySymbol={campaign.target.currencyNode.symbol}
                perk={perk}
                fundraiserId={fundraiserId !== null && fundraiserId}
              />
            </li>
          ))
        }
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  perks: state.perks.perksData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPerksCall: (campaignId) => dispatch(
    fetchPerksAction(campaignId),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Perks);
