import { PaymentElement, useElements } from "@stripe/react-stripe-js";
import React, { useEffect } from "react";

export default function StripePaymentElement({ handlers, options }) {
  const elements = useElements();

  useEffect(() => handlers.setPaymentElements(elements), [elements, handlers]);

  const onChange = (e) => {
    if (handlers.handleCardChange) {
      handlers.handleCardChange(e);
    }
  };

  return (
    <div className="donation-form__payment-method" data-test="donation-form__stripe">
      <PaymentElement
        onChange={onChange}
        options={options}
      />
    </div>
  );
}
