import { Elements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useMemo, useState } from "react";
import { shouldDisplayPaymentMethod, hasPaypal, handlePaymentMethodChanges } from "widgets/Donation/utils/helpers";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/Donation/components/ErrorFallback/ErrorFallback";
import StripePaymentElement from "widgets/PaymentVendor/Stripe/StripePaymentElement";

import { getPaymentElementFieldOptions, getPaymentElementOptions } from "widgets/Donation/utils/fieldHelpers";

import "./__style.scss";

export default function StripePaymentElementWrapper(
  data,
  index,
  formProps,
  handlers,
  donationFormContext,
) {
  const stripePromise = useGetStripe();

  const {
    values: { paymentMethod, frequency },
    setFieldValue,
  } = formProps;

  const hasPaypalMethod = hasPaypal(data) && shouldDisplayPaymentMethod("paypal", { paymentMethod, frequency, data });
  const [elementIndex, setElementIndex] = useState(0);

  const paymentElementOptions = {
    ...getPaymentElementOptions(donationFormContext.donation?.paymentIntentsId),
    externalPaymentMethodTypes: hasPaypalMethod ? ["external_paypal"] : [],
  };

  useEffect(() => {
    const newIndex = Math.floor(Math.random() * (1 - 100 + 1) + 100);
    setElementIndex(newIndex);
  }, [frequency]);

  const handleCardChange = (e) => {
    const updatedPaymentMethod = getPaymentMethodFromEvent(e);

    if (updatedPaymentMethod) {
      handlePaymentMethodChanges(updatedPaymentMethod, data, formProps, donationFormContext.optionalTier);
      setFieldValue("selectedPaymentMethodType", updatedPaymentMethod);

      switch (updatedPaymentMethod) {
        case "paypal":
          setFieldValue("submitButton", "paypal");
          break;
        case "bacs_debit":
          setFieldValue("submitButton", "bacsDebit");
          break;
        default:
          setFieldValue("submitButton", "paymentElement");
          break;
      }
    }
  };

  const getPaymentMethodFromEvent = (event) => {
    if (typeof event?.value?.type === "string") {
      return event?.value?.type.replaceAll("external_", "");
    }

    return event?.value?.type;
  };

  const paymentElementHandlers = {
    ...handlers,
    handleCardChange,
  };

  const options = useMemo(
    () => getPaymentElementFieldOptions(data.donationFormFields),
    [data.donationFormFields],
  );

  return (
    stripePromise ? (
      <ErrorBoundary fallback={<ErrorFallback />}>
        <Elements
          stripe={stripePromise}
          options={paymentElementOptions}
          key={`stripe-pe-${elementIndex}`}
        >
          <StripePaymentElement
            handlers={paymentElementHandlers}
            options={options}
          />
        </Elements>
      </ErrorBoundary>
    ) : null
  );
}

export const useGetStripe = () => {
  const stripeKey = window.Chuffed && window.Chuffed.donationInit.stripePublishableKey;
  const stripePromise = useStripe(stripeKey);

  return stripePromise;
};
