import { PaymentMethodTypes } from "widgets/Donation/types";

export const getPaymentMethodTypes = ({ financialInformation: { paymentMethods } }) =>
  paymentMethods.find((pm) => pm.key === "stripeau")?.paymentMethod.availableMethods || [];

export const hasBacsPaymentMethod = (formData) => {
  if (formData.feeStructure?.currency?.toLowerCase() !== "gbp") {
    return false;
  }

  const paymentMethodTypes = getPaymentMethodTypes(formData);

  return paymentMethodTypes.some((method) => method.toLowerCase() === PaymentMethodTypes.Bacs);
};
