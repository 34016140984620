import React from "react";
import { useField } from "formik";

import OptionalSelector from "components/OptionalSelector";

export const OptionalSelectorWrapper = ({ ...props }) => {
  const [field, setFieldValue] = useField(props);
  const { feeStructure } = props.data;
  const { donationAmount, feeAmount } = props.values;

  return (
    <OptionalSelector
      donationValue={Number.parseFloat(donationAmount)}
      feeStructure={feeStructure}
      feeValue={Number.parseFloat(feeAmount)}
      field={field}
      onChange={(e) => setFieldValue("chuffedAmount", (e.target.value || 0))}
      onBlur={(e) => setFieldValue("chuffedAmount", (e.target.value || 0))}
      onOptionalSelectChange={(e) => props.handlers.setOptionalTier(e)}
      optionalTier={props.donationFormContext.optionalTier}
      setFieldValue={(value) => props.setFieldValue("chuffedAmount", (value || 0))}
    />
  );
};

export const createOptionalSelector = (data, i, formProps, handlers, donationFormContext) => (
  <OptionalSelectorWrapper
    key={i}
    label="Optional Selector"
    name="chuffedAmount"
    type="number"
    data={data}
    handlers={handlers}
    donationFormContext={donationFormContext}
    {...formProps}
  />
);
