import { useField } from "formik";
import _ from "lodash";
import React from "react";

import ErrorDisplay from "components/ErrorDisplay";
import RadioToggle from "components/RadioToggle";

import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import { getFrequencyOptions } from "widgets/Donation/utils/regularGivingHelpers";

export const RegularGivingWrapper = ({ label, ...props }) => {
  const { translate: t } = useLanguageProvider();
  const [field, meta] = useField(props);
  const frequencyOptions = getFrequencyOptions(props);
  _.map(frequencyOptions, (option) => {
    option.text = t(option.text);
    return option;
  });

  return (
    <div className="donation-form__regular-giving" data-test="donation-form__regular-giving-original">
      <ErrorDisplay
        alwaysShowErrors={meta.touched && meta.error?.length > 0}
        customMsg={meta.error}
        field={field}
      >
        <RadioToggle
          defaultValue={field.value}
          field={field}
          options={frequencyOptions}
          {...field}
        />
      </ErrorDisplay>
    </div>
  );
};

export const createRegularGivingSelector = (data, i, formProps, handlers, donationFormContext) => (
  <RegularGivingWrapper
    key={i}
    label="Regular Giving"
    name="frequency"
    type="text"
    data={data}
    handlers={handlers}
    donationFormContext={donationFormContext}
    {...formProps}
  />
);
