export const calculateProcessingFee = (amount, paymentMethod, feeStructure, isDomestic = true) => {
  const donationValue = Number.parseFloat(amount, 10);
  let variableFee = null;
  let fixedFee = null;

  if (feeStructure.feesByPaymentType === false) {
    variableFee = feeStructure.feeBlendedVariable;
    fixedFee = feeStructure.feeBlendedFixed;
  } else {
    switch (paymentMethod) {
      case "amex":
        fixedFee = isDomestic ? feeStructure.feeDomesticFixed : feeStructure.feeIntlFixed;
        variableFee = isDomestic ? feeStructure.feeDomesticVariableAmex : feeStructure.feeIntlVariableAmex;
        break;
      case "paypal":
        fixedFee = feeStructure.feePayPalFixed;
        variableFee = feeStructure.feePayPalVariable;
        break;
      default:
        fixedFee = isDomestic ? feeStructure.feeDomesticFixed : feeStructure.feeIntlFixed;
        variableFee = isDomestic ? feeStructure.feeDomesticVariable : feeStructure.feeIntlVariable;
        break;
    }
  }

  if (!(donationValue > 0)) {
    return 0;
  }

  // Only calculate the fee on the donation value and fee amount
  // Formula for calculating fee:
  // https://support.stripe.com/questions/charging-stripe-fees-to-customers
  const donationWithFee = (donationValue + fixedFee) / (1 - variableFee);
  const feeComponent = donationWithFee - donationValue;

  return feeComponent.toFixed(2);
};
