import { ElementsConsumer, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from "react";
import { connect } from "react-redux";

/**
 * @components
 */
import CampaignCard from "components/CampaignCard";
import CurrencyModal from "components/CurrencyModal";
import { renderFeeConfirmation } from "widgets/Donation/components/FeeConfirmation/renderFeeConfirmation";

/**
 * @helpers
 * */
import { usePrevious } from "helpers/previousHook";
import ScrollToError from "helpers/ScrollToError";

/**
 * @hooks
 * */
import useGetCountryIsoCode from "hooks/useGetCountryIsoCode";

/**
 * @redux
 * */
import {
  createPaymentIntent as createPaymentIntentAction,
  initializeDonationForm as initializeDonationFormAction,
  setCardField as setCardFieldAction,
  setOptionalTier as setOptionalTierAction,
  submitDirectDebit as submitDirectDebitAction,
  submitDonation as submitDonationAction,
  submitPaymentElement as submitPaymentElementAction,
  updatePaymentIntent as updatePaymentIntentAction,
} from "widgets/Donation/store/state";

/**
 * @forms
 * */
import { getInitialValues } from "widgets/Donation/utils/getInitialValues";
import updatePrefillFields from "widgets/Donation/utils/updatePrefillFields";
import { handleValidation, validate } from "widgets/Donation/utils/validation";

/**
 * @utility
 * */
import { buildArrayOfDonationComponents } from "widgets/Donation/utils/buildArrayOfDonationComponents";
import { closeFeeConfirmation, updateFeeAmounts } from "widgets/Donation/utils/feeHelpers";
import { fundraiserChange } from "widgets/Donation/utils/fundraiserHelpers";
import { generateCampaignCard } from "widgets/Donation/utils/generateCampaignCard";
import { donationFormHasAddress } from "widgets/Donation/utils/fieldHelpers";

import {
  decodeRecipientOwner,
  donationFormEnabled,
  handleDonationAmountChanges,
  handlePaymentMethodChanges,
  hasStripe,
  isCustomTemplate,
  renderDonationFormError,
  shouldChangeCountryCode,
} from "widgets/Donation/utils/helpers";
// !change this
import { handlePaymentRequestSubmit } from "widgets/Donation/components/PaymentVendorInputs/createAutoPaymentRequest";

/**
 * @Stripe
 */
import {
  confirmPaymentIntent,
  handleDirectDebitPayment,
  handlePayPalSubmission,
  stripeRedirectToCheckout,
  submitPaymentElementPaymentIntent,
  submitPaymentIntent,
  validateToken,
} from "widgets/Donation/utils/paymentSubmissionHelpers";

import { hasPerk, isNotPerkOrImpactLevel } from "widgets/Donation/utils/perkHelpers";

/**
 * @language
 */
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import { Interweave } from "interweave";
import "./_style.scss";
import useGetPaymentMethodTypes from "./hooks/useGetPaymentMethodTypes";
import { getPaymentMethodTypes } from "./utils/getPaymentMethodOptions";
import TurnstileWrapper from "../../components/TurnstileWrapper";
import { createConfirmationToken } from "./utils/paymentSubmissionHelpers";

let updatedFees;

const setUpdatedFees = (feeInfo) => {
  updatedFees = feeInfo;
};

export const Donation = ({
  optionalTier,
  setOptionalTier,
  setCardField,
  donation,
  createPaymentIntent,
  isSubmitting,
  updatePaymentIntent,
  submitDonation,
  donationFormData,
  submitDirectDebit,
  directDebitSession,
  customContent,
  initializeDonationForm,
  turnstileKey,
  submitChangeCurrency,
  gqlLoadingErrors,
  loginUser,
  logoutUser,
  submitPaymentElement,
}) => {
  const stripe = useStripe();
  const [paymentElements, setPaymentElements] = useState();
  const [currencyModalStatus, setCurrencyModalStatus] = useState(false);
  const [attemptedPaymentIntentCreation, setAttemptedPaymentIntentCreation] = useState();
  const [initializedForm, setInitializedForm] = useState();
  const [showDonation, setShowDonation] = useState();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [feeConfirmation, setFeeConfirmation] = useState();
  const [processingSubmit, setProcessingSubmit] = useState();
  const [donationValues, setDonationValues] = useState();
  const [isPaymentRequestMethod, setIsPaymentRequestMethod] = useState();
  const [foreignCurrencyApplePay, setIsForeignCurrencyApplePay] = useState(); // eslint-disable-line no-unused-vars
  const [canMakePaymentType, setCanMakePaymentType] = useState();
  const [target, setTarget] = useState();
  const [hasBouncedToFirst, setHasBouncedToFirst] = useState();
  const [captchaToken, setCaptchaToken] = useState();
  const [cardError, setCardError] = useState();
  const [isTurnstileInteractive, setIsTurnstileInteractive] = useState();
  const previousDonationFormData = usePrevious(donationFormData);
  const { translate: t } = useLanguageProvider();
  const { getCountryIsoCode } = useGetCountryIsoCode();

  const paymentMethodTypes = useGetPaymentMethodTypes(donationFormData);

  const formRef = useRef();

  useEffect(() => {
    if (hasPerk) {
      setShowDonation(false);
    }

    if (isNotPerkOrImpactLevel(donationFormData) || !hasPerk) {
      setShowDonation(true);
    }

    setTarget(donationFormData?.target || donationFormData?.recipient);
  }, [donationFormData]);

  /* eslint react-hooks/exhaustive-deps: "off" */
  useEffect(() => {
    if (directDebitSession === "completed") {
      const directDebitCheckoutSession = donationFormData.directDebitCheckoutSession;
      stripeRedirectToCheckout({ stripe, directDebitCheckoutSession });
    }
  }, [directDebitSession]);

  const createIntentCall = useCallback(
    (captchaToken, paymentMethodTypes) => {
      createPaymentIntent(
        donationFormData.paymentVendors.filter((vendor) =>
          vendor.includes("stripe"))[0],
        donationFormData.recipientId,
        donationFormData.feeStructure.currency,
        captchaToken,
        paymentMethodTypes,
      );
    },
    [
      donationFormData.paymentVendors,
      donationFormData.recipientId,
      donationFormData.feeStructure,
      createPaymentIntent,
    ],
  );

  useEffect(() => {
    if (
      donation?.paymentIntentsId === null
      && hasStripe(donationFormData)
      && !attemptedPaymentIntentCreation
      && captchaToken
      && paymentMethodTypes
    ) {
      setAttemptedPaymentIntentCreation(true);
      createIntentCall(captchaToken, paymentMethodTypes);
    }

    if (
      donationFormData
      && !initializedForm
      && !hasStripe(donationFormData)
      && captchaToken
    ) {
      initializeDonationForm();
      setInitializedForm(true);
    }
  }, [
    donation,
    createPaymentIntent,
    setInitializedForm,
    captchaToken,
  ]);

  useEffect(() => {
    if (stripe && hasStripe(donationFormData)) {
      /**
       * checks if apple pay and google pay are available
       */
      const paymentRequest = stripe.paymentRequest({
        country: shouldChangeCountryCode(
          donationFormData.feeStructure.countryCode,
        ),
        currency: donationFormData.feeStructure.currency,
        total: {
          label: `${decodeRecipientOwner(donationFormData.recipientOwner)}`,
          amount: 0,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      paymentRequest.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(paymentRequest);
          setCanMakePaymentType(result);
        }
      });
    }
  }, [stripe, donationValues]);

  useEffect(() => {
    if (paymentRequest && donationValues) {
      if (paymentRequest.hasRegisteredListener("paymentmethod")) {
        return;
      }

      paymentRequest.on("paymentmethod", async (ev) => {
        const isValidToken = await validateToken(
          ev.paymentMethod,
          donationFormData,
          donationValues,
          donationFormContext,
          handlers,
          updatedFees,
        );
        if (isValidToken.isValid || updatedFees?.isSubmittingFromConfirmation) {
          handlers.setIsPaymentRequestMethod(true);
          handlers.updatePaymentIntent({
            ...donationValues,
            ...updatedFees,
            ...donationFormData,
            ...donation,
            closeFeeConfirmation: closeFeeConfirmation(handlers),
            doSubmit: true,
            fromPaymentRequestButton: true,
            paymentIntentSecret: donation.paymentIntentsId,
            paymentIntentComplete: ev.complete,
            paymentMethod: ev.paymentMethod,
            stripeCardBrand: ev.paymentMethod.card.brand,
            stripeCardCountry: ev.paymentMethod.card.country,
            stripe,
            submitPaymentIntent: handlers.confirmPaymentIntent,
            cardErrorHandler,
          });
        } else {
          ev.complete("fail");
          updateFeeAmounts(
            true,
            isValidToken.updatedFeeConfirmation,
            formRef.current.setFieldValue,
          );
        }
      });
    }
  }, [
    paymentRequest,
    donationValues,
  ]);

  useEffect(() => {
    if (!previousDonationFormData) {
      return;
    }

    if (
      previousDonationFormData.feeStructure !== donationFormData.feeStructure
      && donationFormData.exchangeRate
    ) {
      const updatedDonationAmount = Number(
        formRef.current.values.donationAmount * donationFormData.exchangeRate,
      ).toFixed(0);
      handleDonationAmountChanges(
        updatedDonationAmount,
        donationFormData,
        formRef.current,
        optionalTier,
      );
    }

    if (previousDonationFormData.perks !== donationFormData.perks) {
      const updatedPerksCart = {
        ...formRef.current.values.perksCart,
        ...donationFormData.perks,
      };
      formRef.current.setFieldValue("perksCart", updatedPerksCart);
    }

    if (previousDonationFormData.prefill !== donationFormData.prefill) {
      updatePrefillFields(donationFormData.prefill, formRef.current);
    }
  }, [previousDonationFormData, donationFormData]);

  const handlePaymentElementSubmission = (
    formValues,
    stripe,
    elements,
    donationFormData,
    donationFormContext,
    handlers,
  ) => {
    const {
      closeFeeConfirmation,
    } = handlers;

    setIsPaymentRequestMethod(false);
    submitPaymentElement({
      ...formValues,
      ...donationFormData,
      ...donation,
      elements: paymentElements,
      closeFeeConfirmation: closeFeeConfirmation(handlers),
      doSubmit: true,
      fromPaymentRequestButton: false,
      paymentIntentSecret: donation.paymentIntentsId,
      stripe,
      submitPaymentElementPaymentIntent,
      cardErrorHandler,
      paymentMethodTypes: getPaymentMethodTypes(donationFormData),
    })
      .catch((error) => {
        setCardError(error ?? "There was an error submitting your payment.");
      });
  };

  const createSubmitConfirmationDetails = (values, formData) => {
    const billingDetails = {
      name: `${values.firstName} ${values.lastName}`,
      email: values.email,
    };

    if (formData?.donationFormFields && donationFormHasAddress(formData.donationFormFields)) {
      const addressCountry = values.addressCountry ? getCountryIsoCode(values.addressCountry) : "";

      billingDetails.address = {
        line1: values.addressLine1 ?? "",
        // line2 must be provided in the data, but we never collect it via Chuffed, so this is always blank
        line2: "",
        city: values.addressLocality ?? "",
        state: values.addressRegion ?? "",
        postal_code: values.addressPostcode ?? "",
        country: addressCountry,
      };
    }

    return billingDetails;
  };

  const handleSubmit = async (
    values,
    stripe,
    elements,
    donationFormData,
    donationFormContext,
    handlers,
    setFieldValue,
    setFieldTouched,
  ) => {
    if (values.submitButton === "paymentElement") {
      setDonationValues(values);

      const token = await createConfirmationToken(
        paymentElements,
        stripe,
        createSubmitConfirmationDetails(values, donationFormData),
      );

      const isValidToken = await validateToken(
        token,
        donationFormData,
        values,
        donationFormContext,
        handlers,
        updatedFees,
      );

      if (isValidToken.isValid) {
        handlePaymentElementSubmission(
          values,
          stripe,
          paymentElements,
          donationFormData,
          donationFormContext,
          handlers,
          setFieldValue,
        );
      } else {
        updateFeeAmounts(
          true,
          isValidToken.updatedFeeConfirmation,
          setFieldValue,
        );
      }
    }

    if (values.submitButton === "bacsDebit") {
      setDonationValues(values);

      handlePaymentElementSubmission(
        values,
        stripe,
        paymentElements,
        donationFormData,
        donationFormContext,
        handlers,
        setFieldValue,
      );
    }

    if (values.submitButton === "paymentRequest") {
      handlePaymentRequestSubmit(
        values,
        donationFormData,
        donationFormContext,
        handlers,
        setFieldTouched,
      );
    }

    if (values.submitButton === "paypal") {
      handlePayPalSubmission(
        values,
        donationFormData,
        donationFormContext,
        handlers,
        setFieldValue,
      );
    }
  };

  const handleCurrencyModalStatus = () => setCurrencyModalStatus(!currencyModalStatus);

  const cardErrorHandler = (error) => {
    setCardError(error);
    donationFormContext.donation.isSubmitting = false;
  };

  const handlers = {
    handlePaymentMethodChanges: (
      paymentMethod,
      campaignData,
      formProps,
      optionalTier,
    ) => handlePaymentMethodChanges(paymentMethod, campaignData, formProps, optionalTier),
    handleDonationAmountChanges: (
      donationAmount,
      campaignData,
      formProps,
      optionalTier,
    ) => handleDonationAmountChanges(donationAmount, campaignData, formProps, optionalTier),
    handleStripeSubmit: () => { },
    openCurrencyModal: () => handleCurrencyModalStatus,
    saveUpdatedCurrency: () => { },
    setOptionalTier: (tier) => setOptionalTier(tier),
    setCardField: (field) => setCardField(field),
    submitDonation: (formData) => submitDonation(formData),
    updatePaymentIntent: (formData) => updatePaymentIntent(formData),
    setProcessingSubmit: () => setProcessingSubmit,
    setFeeConfirmation: (data) => setFeeConfirmation(data),
    setDonationValues: (values) => setDonationValues(values),
    submitPaymentIntent: (
      data,
      redirectUrl,
      closeFeeConfirmation,
      paymentMethod,
      complete,
      stripe,
      cardElem,
      cardErrorHandler,
    ) => submitPaymentIntent(
      data,
      redirectUrl,
      closeFeeConfirmation,
      paymentMethod,
      complete,
      stripe,
      cardElem,
      cardErrorHandler,
    ),
    closeFeeConfirmation: (handlers) => closeFeeConfirmation(handlers),
    updateFeeAmounts: (
      isNew,
      feeConfirmation,
      setFieldValue,
    ) => updateFeeAmounts(isNew, feeConfirmation, setFieldValue),
    setIsPaymentRequestMethod: (paymentRequestMethod) => setIsPaymentRequestMethod(paymentRequestMethod),
    setIsForeignCurrencyApplePay: () => setIsForeignCurrencyApplePay,
    confirmPaymentIntent: (
      data,
      redirectUrl,
      closeFeeConfirmation,
      paymentMethod,
      complete,
      stripe,
    ) => confirmPaymentIntent(data, redirectUrl, closeFeeConfirmation, paymentMethod, complete, stripe),
    fundraiserChange: (
      e,
      donationFormData,
      handlers,
      formProps,
    ) => fundraiserChange(e, donationFormData, handlers, formProps),
    setTarget: (target) => setTarget(target),
    handleDirectDebitPayment: (
      event,
      formValues,
      handlers,
      donationFormContext,
    ) => handleDirectDebitPayment(event, formValues, handlers, donationFormContext),
    submitDirectDebitAction: (formData) => submitDirectDebit({ ...formData, ...donationFormData }),
    setShowDonation: () => setShowDonation,
    setUpdatedFees: (feeInfo) => setUpdatedFees(feeInfo),
    setPaymentElements: (elements) => setPaymentElements(elements),
    logoutUser: () => logoutUser(),
    loginUser: (user) => loginUser(user),
  };

  const handleCurrencyChange = (countryIsoCode) => {
    submitChangeCurrency({
      variables: {
        feeStructureCountry: countryIsoCode,
        campaignCurrency:
          donationFormData.feeStructure.currency
          || donationFormData.campaignCurrency,
      },
    });
  };

  const donationFormContext = useMemo(
    () => ({
      hasPerk,
      optionalTier,
      paymentRequest,
      canMakePaymentType,
      donation,
      processingSubmit,
      feeConfirmation,
      isSubmitting,
      isPaymentRequestMethod,
      showDonation,
      target,
      gqlLoadingErrors,
    }),
    [
      optionalTier,
      paymentRequest,
      canMakePaymentType,
      donation,
      processingSubmit,
      feeConfirmation,
      isSubmitting,
      isPaymentRequestMethod,
      showDonation,
      target,
      gqlLoadingErrors,
    ],
  );

  const validateFormik = (values) =>
    handleValidation({
      values,
      donationFormData,
      validate,
      handleSubmitFailed: ScrollToError,
      hasBouncedToFirst,
      setHasBouncedToFirst,
    });

  return !donation.isLoading ? (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <div className="payment-wrapper">
          {donationFormEnabled(
            donationFormData,
            donation.paymentIntentsId,
          ) ? (
            <div className="payment donation-form">
              <Interweave content={customContent} className="payment__header" />
              <div className="payment__body">
                {!isCustomTemplate(donationFormData) && (
                <div className="payment__campaign">
                  <div
                    className="payment__campaign-card --v2 --desktop"
                    data-test="donation-form-campaign-card"
                  >
                    <CampaignCard
                      campaign={generateCampaignCard(
                        target,
                        donationFormData,
                      )}
                    />
                  </div>
                </div>
                )}

                <div className="payment__donate --v2">
                  <Formik
                    innerRef={formRef}
                    initialValues={getInitialValues(donationFormData)}
                    validate={validateFormik}
                    onSubmit={(
                      values,
                      { setFieldValue, setFieldTouched },
                    ) =>
                      handleSubmit(
                        values,
                        stripe,
                        elements,
                        donationFormData,
                        donationFormContext,
                        handlers,
                        setFieldValue,
                        setFieldTouched,
                      )}
                  >
                    {({ ...props }) => (
                      <Form data-test="donation-form">
                        {buildArrayOfDonationComponents(
                          donationFormData,
                          handlers,
                          props,
                          donationFormContext,
                        )}
                      </Form>
                    )}
                  </Formik>
                  {cardError && (
                  <p
                    className="donation-form__card-error well"
                    data-test="donation-form-card-error"
                  >
                    {cardError}
                    {" "}
                    Please try an alternative payment method.
                  </p>
                  )}
                </div>
              </div>
            </div>
            ) : (
              renderDonationFormError(
                donationFormData.paymentVendors,
                donation.paymentIntentsId,
              )
            )}

          {feeConfirmation?.isVisible
                  && !feeConfirmation?.isSubmittingFromConfirmation
                  && renderFeeConfirmation(
                    donationFormData,
                    donationValues,
                    feeConfirmation,
                    donationFormContext,
                    handlers,
                    formRef.current.setFieldValue,
                  )}

          {currencyModalStatus && (
          <CurrencyModal
            currentCurrency={donationFormData.feeStructure.currency}
            currencyModaOpen={currencyModalStatus}
            exitCurrencyConversion={handleCurrencyModalStatus}
            submitChangeCurrency={handleCurrencyChange}
          />
          )}

        </div>
      )}
    </ElementsConsumer>
  ) : (
    <div>
      {!isTurnstileInteractive && (
        <div className="payment__loader">
          <div className="payment__loader-content">
            <span className="payment__loader-text">
              {t("Loading payment form")}
              ...
            </span>
            <div className="payment__loader-spinner">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
      )}

      {!captchaToken && (
      <div className="turnstile__wrapper">
        <TurnstileWrapper
          onChange={(token) => setCaptchaToken(token)}
          captchaKey={turnstileKey}
          setTurnstileInteractive={setIsTurnstileInteractive}
        />
      </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  optionalTier: state.donation.optionalTier,
  donation: state.donation,
  directDebitSession: state.donation.directDebitSession,
  recaptchaErrorType: state.donation.recaptchaErrorType,
});

const mapDispatchToProps = (dispatch) => ({
  updatePaymentIntent: (campaignId) =>
    dispatch(updatePaymentIntentAction(campaignId)),
  createPaymentIntent: (
    vendor,
    campaignId,
    currency,
    captchaToken,
    captchaVersion,
    paymentMethodTypes,
  ) => dispatch(
    createPaymentIntentAction(vendor, campaignId, currency, captchaToken, captchaVersion, paymentMethodTypes),
  ),
  setOptionalTier: (tier) => dispatch(setOptionalTierAction(tier)),
  setCardField: (field) => dispatch(setCardFieldAction(field)),
  submitDonation: (formData) => dispatch(submitDonationAction(formData)),
  submitDirectDebit: (formData) => dispatch(submitDirectDebitAction(formData)),
  submitPaymentElement: (formData) => dispatch(submitPaymentElementAction(formData)),
  initializeDonationForm: () => dispatch(initializeDonationFormAction()),
});

const DonationConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Donation);

export default DonationConnected;
