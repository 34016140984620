import { createCampaignCardWrapper } from "widgets/Donation/components/CampaignCardWrapper/createCampaignCardWrapper";
import { createDonationToCampaignLabel } from "widgets/Donation/components/CampaignLabel/createDonationToCampaignLabel";
import { createCustomQuestions } from "widgets/Donation/components/CustomFields/createCustomQuestions";
import { createDetails } from "widgets/Donation/components/Details/createDetails";
import { createDisclaimer } from "widgets/Donation/components/Disclaimer/createDisclaimer";
import { createDonationFormHeading } from "widgets/Donation/components/DonationFormHeading/createDonationFormHeading";
import { createDonationSelector } from "widgets/Donation/components/DonationSelector/createDonationSelector";
import { createFundraisersDropdown } from "widgets/Donation/components/FundraiserDropdown/createFundraisersDropdown";
import { createImpactLevels } from "widgets/Donation/components/ImpactLevelSelector/createImpactLevels";
import { createMatchedGiving } from "widgets/Donation/components/MatchedGiving/createMatchedGiving";
import { createOptionalSelector } from "widgets/Donation/components/OptionalSelector/createOptionalSelector";
import { createAutoPaymentRequest } from "widgets/Donation/components/PaymentVendorInputs/createAutoPaymentRequest";
import { createPaypalDonation } from "widgets/Donation/components/PaymentVendorInputs/createPaypalDonation";
import createPerkSelector from "widgets/Donation/components/PerkSelector/CreatePerkSelector";
import { createProcessingFee } from "widgets/Donation/components/ProcessingFeeLabel/createProcessingFee";
import { createRegularGivingFrequencyLabel } from "widgets/Donation/components/RegularGiving/createRegularGivingFrequencyLabel";
import { createRegularGivingSelector } from "widgets/Donation/components/RegularGiving/createRegularGivingSelector";
import { createSectionSubheading } from "widgets/Donation/components/SectionSubheading/createSectionSubheading";
import { createDonationTotal } from "widgets/Donation/components/DonationTotal/createDonationTotal";
import StripeVendorLoader from "../components/PaymentVendorInputs/Stripe/StripePaymentElementWrapper";
import SubmitStripeButton from "../components/PaymentVendorInputs/Stripe/SubmitStripeButton";
import { createOptionalTipLabel } from "../components/OptionalTipLabel/createOptionalTipLabel";

// this determines where placement of ui elements
export const formComponents = {
  heading: createDonationFormHeading,
  campaignCard: createCampaignCardWrapper,
  fundraisersDropdownSubheading: (donationFormData, i, formProps, handlers, donationFormContext, heading) => createSectionSubheading(donationFormData, i, formProps, handlers, donationFormContext, "FUNDRAISER_DROPDOWN"), // eslint-disable-line no-unused-vars
  fundraisersDropdown: createFundraisersDropdown,
  donationSectionSubheading: (donationFormData, i, formProps, handlers, donationFormContext, heading) => createSectionSubheading(donationFormData, i, formProps, handlers, donationFormContext, "DONATION"), // eslint-disable-line no-unused-vars
  matchedGiving: createMatchedGiving,
  impactLevels: createImpactLevels,
  perksSelector: createPerkSelector,
  donationSelector: createDonationSelector,
  regularGiving: createRegularGivingSelector,
  optionalSelector: createOptionalSelector,
  regularGivingFrequencyLabel: createRegularGivingFrequencyLabel,
  detailsSectionSubheading: (donationFormData, i, formProps, handlers, donationFormContext, heading) => createSectionSubheading(donationFormData, i, formProps, handlers, donationFormContext, "DETAILS"), // eslint-disable-line no-unused-vars
  detailsSection: createDetails,
  customQuestions: createCustomQuestions,
  paymentSectionSubheading: (donationFormData, i, formProps, handlers, donationFormContext, heading) => createSectionSubheading(donationFormData, i, formProps, handlers, donationFormContext, "PAYMENT"), // eslint-disable-line no-unused-vars
  disclaimer: createDisclaimer,
  autoPayment: createAutoPaymentRequest,
  stripe: StripeVendorLoader,
  donationCampaignLabel: createDonationToCampaignLabel,
  paymentProcessingFees: createProcessingFee,
  optionalTipLabel: createOptionalTipLabel,
  optionalSelectorExperiment: createOptionalSelector,
  donationTotal: createDonationTotal,
  submitStripeButton: SubmitStripeButton,
  paypal: createPaypalDonation,
};
