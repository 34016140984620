import countryIsoCodes from "data/country-iso-codes.json";

const useGetCountryIsoCode = () => {
  const getCountryIsoCode = (country) => {
    if (countryIsoCodes[country]) {
      return countryIsoCodes[country];
    }

    return null;
  };

  return {
    getCountryIsoCode: (country) => getCountryIsoCode(country),
  };
};

export default useGetCountryIsoCode;
