const activeFormFieldStatuses = ['Enabled', 'Required'];
export const fieldRequired = (fieldStatus) => (fieldStatus === null || fieldStatus === void 0 ? void 0 : fieldStatus.toLowerCase()) === 'required' || (fieldStatus === null || fieldStatus === void 0 ? void 0 : fieldStatus.toLowerCase()) === 'locked';
export const fieldIsEnabled = (fieldStatus) => {
    if (!fieldStatus) {
        return false;
    }
    const normalizeFieldStatus = fieldStatus.toLowerCase();
    return ['required', 'locked', 'enabled'].indexOf(normalizeFieldStatus) !== -1;
};
export const donationFormHasAddress = (donationFormFields) => donationFormFields.some((field) => field.id === 'address' && activeFormFieldStatuses.includes(field.status));
export const getPaymentElementFieldOptions = (donationFormFields) => {
    const options = {
        ...getBasePaymentElementFieldOptions(),
        fields: {
            billingDetails: {
                name: 'never',
                email: 'never',
            },
        },
    };
    if (donationFormHasAddress(donationFormFields)) {
        options.fields.billingDetails.address = 'never';
    }
    return options;
};
export const getBasePaymentElementFieldOptions = () => ({
    wallets: {
        applePay: 'never',
        googlePay: 'never',
    },
    layout: {
        type: 'accordion',
    },
});
export const getPaymentElementOptions = (intent) => ({
    clientSecret: intent,
    appearance: {
        theme: 'stripe',
        variables: {
            fontSizeBase: '15px',
            fontWeightNormal: '500',
            colorPrimary: '#1fa8df',
            fontFamily: "'Source Sans Pro', 'Helvetica Neue', helvetica, arial, sans-serif",
        },
    },
});
