import { isValidEmail } from "helpers/validators";
import validateBoolean from "helpers/validateBoolean";

import { shouldShowFrequency } from "widgets/Donation/utils/regularGivingHelpers";
import { enabledCustomFields } from "widgets/Donation/utils/getInitialValues";
import { formatDonationDonorDetails } from "widgets/Donation/components/Details/renderDonorDetails";
import { fieldRequired, fieldIsEnabled } from "widgets/Donation/utils/fieldHelpers";
import { PaymentMethodTypes } from "widgets/Donation/types";

export const validate = (values, props) => {
  const errors = {};

  const formattedDonorFields = formatDonationDonorDetails(props.donationFormFields);
  // If the user has selected BACS as a payment method, address becomes required
  const validateAddress = (
    fieldRequired(formattedDonorFields.address.status)
    || shouldValidateAddressForBacs(values, formattedDonorFields)
  );
  const validatePhone = fieldRequired(formattedDonorFields.phone.status);
  const validateTitle = fieldRequired(formattedDonorFields.title.status);

  if (!values.targetId && (props.fundraisers && props.fundraisers.length >= 1)) {
    errors.targetId = "Please select campaign or fundraiser";
  }

  if (values.donationAmount === undefined
        || values.donationAmount <= 0
        || isNaN(values.donationAmount)) {
    const errorSelectText = (props.levels.length > 0) ? "select an impact level" : "select";
    errors.donationAmount = `Please ${errorSelectText} or enter a donation amount`;
  }

  if (shouldShowFrequency(props.regularGiving, props.perk) === true) {
    if (values.frequency === undefined || values.frequency.length === 0) {
      errors.frequency = "Please select a frequency";
    }
  }

  if (validateTitle === true) {
    if (values.title === undefined || values.title.trim().length === 0) {
      errors.title = "Please select your title";
    }
  }

  if (values.firstName === undefined
        || values.firstName.trim().length === 0) {
    errors.firstName = "Please enter your first name";
  }

  if (values.firstName?.trim().length !== 0 && values.firstName?.trim().length <= 1) {
    errors.firstName = "Please enter more than 1 letter for first name";
  }

  if (values.lastName === undefined
        || values.lastName.trim().length === 0) {
    errors.lastName = "Please enter your last name";
  }

  if (values.lastName?.trim().length !== 0 && values.lastName?.trim().length <= 1) {
    errors.lastName = "Please enter more than 1 letter for last name";
  }

  if (validatePhone === true) {
    if (values.phone === undefined || (typeof (values.phone) === "string") && values.phone.trim().length === 0) {
      errors.phone = "Please enter your phone number";
    }
  }

  if (values.email === undefined
        || values.email.trim().length === 0) {
    errors.email = "Please enter your email address";
  } else if (isValidEmail(values.email) === false) {
    errors.email = "Please enter a valid email address";
  }

  // Validate address fields
  if (validateAddress === true) {
    if (values.addressLine1 === undefined || values.addressLine1.trim().length === 0) {
      errors.addressLine1 = "Please enter your address";
    }

    if (values.addressLocality === undefined || values.addressLocality.trim().length === 0) {
      switch (values.addressCountry) {
        case "Australia":
        case "United Kingdom":
        case "United States":
          errors.addressLocality = "Please enter your city/town";
          break;
        default:
          errors.addressLocality = "Please enter your city/locality";
      }
    }

    if (values.addressRegion === undefined || values.addressRegion.trim().length === 0) {
      switch (values.addressCountry) {
        case "Australia":
        case "United States":
          errors.addressRegion = "Please select your state";
          break;
        case "United Kingdom":
          errors.addressRegion = "Please enter your county";
          break;
        default:
          errors.addressRegion = "Please enter your state/province/region";
      }
    }

    if (values.addressPostcode === undefined || values.addressPostcode.trim().length === 0) {
      switch (values.addressCountry) {
        case "Australia":
          errors.addressPostcode = "Please enter your postcode";
          break;
        case "United Kingdom":
          errors.addressPostcode = "Please enter your postal code";
          break;
        case "United States":
          errors.addressPostcode = "Please enter your ZIP code";
          break;
        default:
          errors.addressPostcode = "Please enter your ZIP/postal code";
      }
    }
  }

  if (values.rememberToggle && !values.setPassword) {
    errors.setPassword = "Please set password";
  }

  validateCustomFields(errors, values, props);

  // Validate the `card` field manually
  // Check if the form has been submitted, if so, set the card field as `touched`
  // This will cause any errors to display.

  return errors;
};

export const handleValidation = ({
  values,
  donationFormData,
  validate,
  handleSubmitFailed,
  hasBouncedToFirst,
  setHasBouncedToFirst,
}) => {
  const hasErrors = validate(values, donationFormData);

  if (hasErrors && values.submitButton && !hasBouncedToFirst) {
    handleSubmitFailed(hasErrors);
    setHasBouncedToFirst(true);
  }

  return hasErrors;
};

export const isInvalidCustomAnswer = (value) => value === undefined || value.trim().length === 0 || value === "";

export const isInvalidToggleAnswer = (value) => {
  const ensureBoolean = validateBoolean(value);

  return ensureBoolean !== true;
};

export const validateCustomFields = (errors, values, props) => {
  if (props.donationFormCustomFields === undefined) {
    return;
  }

  const customErrors = errors;

  const fieldsToValidate = props?.fields
    ? props.fields.filter((field) => field.includes("customField") === true)
    : enabledCustomFields(props.donationFormCustomFields);

  const customFieldSettings = enabledCustomFields(props.donationFormCustomFields);

  fieldsToValidate.forEach((field, index) => {
    const fieldName = props?.fields ? field : `customField${index}`;

    if (customFieldSettings[index].status === "Required") {
      // If a toggle, needs to be on
      if (customFieldSettings[index].type === "Checkbox") {
        if (isInvalidToggleAnswer(values[fieldName])) {
          customErrors[fieldName] = "Please switch on this toggle";
        }
      } else if (isInvalidCustomAnswer(values[fieldName])) {
        customErrors[fieldName] = "Please answer this question";
      }
    }
  });

  return customErrors;
};

const shouldValidateAddressForBacs = (values, donorFields) => {
  if (values.selectedPaymentMethodType !== PaymentMethodTypes.Bacs) {
    return false;
  }

  return fieldIsEnabled(donorFields.address.status);
};
