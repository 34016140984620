import { isCardPayment } from "widgets/Donation/utils/helpers";
import { PaymentMethodTypes } from "widgets/Donation/types";

export const handleStripeSubmit = (handlers) => {
  handlers.setIsPaymentRequestMethod(false);
  // Ensure that we display an error message by setting `touched` to true
  handlers.setCardField({
    touched: true,
  });
};

export const isSubmittingDonation = (isSubmitting, paymentMethod, feeStructure, donationFormContext) => isSubmitting
  && (isCardPayment(paymentMethod) || !feeStructure.feesByPaymentType)
  && !donationFormContext.isPaymentRequestMethod;

export const getPayButtonLabel = (paymentMethod) => {
  switch (paymentMethod) {
    case PaymentMethodTypes.Bacs:
      return "via Direct Debit";
    case PaymentMethodTypes.Card:
      return "with Card";
    case PaymentMethodTypes.Paypal:
      return "via PayPal";
    default:
      return "";
  }
};
