import LinkButton from "components/LinkButton";
import TextTranslator from "components/TranslateText/TextTranslator";
import { formatCurrency } from "helpers/formatCurrency";
import React from "react";
import { calculateDonationTotal } from "widgets/Donation/utils/donationCalculatorHelpers";
import { getPayButtonLabel, handleStripeSubmit } from "./utils";

export default function StripePaymentElement(
  data,
  index,
  formProps,
  handlers,
  donationFormContext,
) {
  const {
    recipient: {
      camp_status,
    },
    feeStructure,
  } = data;

  const {
    donation: {
      isSubmitting,
    },
  } = donationFormContext;

  const {
    values: { selectedPaymentMethodType },
  } = formProps;

  const donationTotal = calculateDonationTotal(feeStructure, formProps);
  const submitPayment = () => handleStripeSubmit(handlers);

  return (
    <LinkButton
      id="js-donate-action-stripe"
      isBold
      isDisabled={camp_status === "draft" || donationTotal <= 0}
      isFullWidth
      isLoading={isSubmitting}
      loadingMessage={<TextTranslator>Submitting payment...</TextTranslator>}
      look={selectedPaymentMethodType === "paypal" ? "paypal" : "primary"}
      type="submit"
      onClick={submitPayment}
      size="large"
      className="mt-1"
    >
      <TextTranslator replacements={
        {
          currency: formatCurrency(donationTotal, feeStructure),
        }
      }
      >
        Pay :currency
      </TextTranslator>
      {" "}
      {getPayButtonLabel(selectedPaymentMethodType)}
    </LinkButton>
  );
}
