import React from "react";

import { formatCurrency } from "helpers/formatCurrency";

import "./_style.scss";
import TextTranslator from "components/TranslateText/TextTranslator";

export const createOptionalTipLabel = (data, i, formProps) => {
  const {
    values: {
      chuffedAmount,
    },
  } = formProps;

  const feeStructure = data.feeStructure;

  return (
    <div
      data-test="donation-form-optional-tip-label"
      className="donation-form__line-item --fee donation-fee__container"
      key={i}
    >
      <input type="hidden" name="chuffedAmount" value={chuffedAmount ?? ""} />
      <span className="donation-form__line-item-label">
        <TextTranslator>
          Thanks for tipping Chuffed.org:
        </TextTranslator>
      </span>
      <span className="donation-form__line-item-value" data-testid="donation-optional-tip-label-amount">
        { formatCurrency(chuffedAmount, feeStructure, false) }
      </span>
    </div>
  );
};
